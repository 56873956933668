
<template>
  <div>
    <b-card no-body class="m-0">
      <b-row class="d-flex justify-content-between gap-1">
        <b-col class="mt-1 d-flex pagination-button">
          <v-select
            :clearable="false"
            v-model="per_page"
            class="per-page-selector ml-1"
            style="width: 100px"
            :options="['5', '10', '20', '50', '100']"
            @input="changePagination()"
          />
          <b-button variant="primary" @click="openModal"
            >Add New Category</b-button
          >
        </b-col>
        <div class="ml-1 mt-1 mr-1">
          <b-form-input
            placeholder="Search..."
            v-model="search"
            @input="handleSearch()"
            class="mr-1"
            style="width: 200px"
          />
        </div>
      </b-row>
      <div class="m-2">
        <section id="card-images" class="container1">
          <b-row
            md="12"
            style="align-item: center; justify-content: center; display: flex"
          >
            <b-table
              :busy="isLoading"
              ref="refUserListTable"
              class="position-relative"
              :items="categories"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              @sort-changed="onSortChanged"
            >
              <template #table-busy>
                <div class="text-center text-secondary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(id)="data">
                <span class="text-primary font-weight-bolder">
                  {{ data.index + 1 }}
                </span>
              </template>
              <template #cell(Action)="data">
                <div class="d-flex justify-content-start align-items-center">
                  <!-- <feather-icon
            icon="MailIcon"
            size="22"
            class="m-1"
            style="cursor: pointer"
          /> -->
                  <feather-icon
                    icon="EditIcon"
                    size="22"
                    class="m-1"
                    style="cursor: pointer"
                    @click="handleEditForm(data.item)"
                  />
                  <feather-icon
                    icon="Trash2Icon"
                    size="22"
                    class="m-1"
                    style="cursor: pointer"
                    @click="removeRow(data.item.id)"
                  />
                </div>
              </template>
            </b-table>
            <div class="w-100 d-flex justify-content-between p-1">
              <div>
                <span class="text-nowrap">
                  Showing {{ paginationStart }} to {{ paginationEnd }} of
                  {{ paginationTotal }}</span
                >
              </div>
              <b-pagination
                :total-rows="paginationTotal"
                pills
                :per-page="per_page"
                v-model="paginationValue"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="changePagination()"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-row>
        </section>
      </div>
    </b-card>
    <b-modal
      hide-footer
      v-model="categoryModal"
      no-close-on-backdrop
      centered
      size="md"
      :title="ifEdit ? 'Edit Category' : 'Add Category'"
    >
      <validation-observer ref="Rules">
        <b-row>
          <b-col>
            <b-form-group>
              <label class="font-weight-bold mt-1" for="h-first-name"
                >Name</label
              >
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Name"
              >
                <b-form-input v-model="name" placeholder="Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12" class="d-flex justify-content-start mt-2">
            <b-button
              variant="primary"
              class="mr-1 font-weight-bold"
              @click.prevent="handleForm($event)"
              :disabled="flag"
            >
              {{ ifEdit ? "Update" : "Submit" }}
            </b-button>
            <b-button
              type="reset"
              class="font-weight-bold"
              style="font-size: 15px; font-family: 'Public Sans', sans-serif"
              variant="primary"
              @click="closeModal"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BImg,
  BButton,
  BCard,
  BForm,
  BTable,
  BPagination,
  BFormFile,
  BFormInput,
  BFormGroup,
  BBadge,
  BSpinner,
  BFormTimepicker,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BMedia,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import axios from "@/component/axios";
import { required, integer } from "@validations";
// import { number } from "vee-validate/dist/rules";
import _ from "lodash";
export default {
  components: {
    BMediaBody,
    BMediaAside,
    BAvatar,
    BMedia,
    BFormTimepicker,
    BRow,
    BCol,
    BPagination,
    vSelect,
    BCardText,
    BImg,
    BCardTitle,
    BButton,
    BCard,
    BForm,
    BTable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BBadge,
    ValidationProvider,
    BSpinner,
    ValidationObserver,
    required,
    integer,
  },
  data() {
    return {
      name: "",
      tableColumns: [
        { key: "id", label: "No" },
        { key: "name", label: "Name", sortable: true },
        // { key: "comment", label: "Comment" },
        // { key: "sets", label: "Set" },
        // { key: "reps", label: "Reps" },
        // { key: "time", label: "Time" },
        { key: "Action", label: "Actions" },
      ],
      isSortDirDesc: true,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      selectedId: 0,
      isLoading: false,
      ifEdit: false,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      paginationStart: 0,
      paginationEnd: 0,
      flag: false,
      params: {},
      search: "",
      categories: [],
      categoryModal: false,
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    handleEditForm(item) {
      this.ifEdit = true;
      this.selectedId = item.id;
      this.categoryModal = true;
      this.name = item.name;
    },
    openModal() {
      this.ifEdit = false;
      this.name=''
      this.categoryModal = true;
    },
    closeModal() {
      this.categoryModal = false;
    },
    handleSearch: _.debounce(function () {
      this.getCategory();
    }, 1000),
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      this.getCategory();
    },
    changePagination() {
      this.getCategory();
    },
    handleForm() {
      this.$refs.Rules.validate().then((success) => {
        if (success) {
          this.flag = true;
          let data = {
            name: this.name,
          };
          const requestOptions = {
            method: `${this.ifEdit ? "put" : "post"}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            url: this.ifEdit
              ? `${this.baseApi}videocategories/update/${this.selectedId}`
              : `${this.baseApi}videocategories/add`,
            data: data,
          };
          axios(requestOptions)
            .then((response) => {
                if(response.data.success){
                    this.$swal({
                        title: "Submitted",
                        text: this.ifEdit
                        ? "Category Updated Successfully"
                        : " Category Added Successfully",
                        icon: "success",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                    this.getCategory();
                    this.categoryModal=false
                }else{
                       this.$swal({
                        title: "Error",
                        text: response.data.message,
                        icon: "error",
                        customClass: {
                        confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                }

            })
            .catch((error) => {
              if (error.response) {
                this.$swal({
                  title: "Error!",
                  html: "Internal Server Error",
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              } else if (error.request) {
                // The request was made but no response was received
                console.error("No response received");
              } else {
                // Something happened in setting up the request that triggered an Error
                console.error("Error setting up the request", error.message);
              }
            })
            .finally(() => {
              this.flag = false;
            });
        } else {
          this.$swal({
            title: "Warning",
            text: "Please Fill Name",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    async getCategory() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}videocategories/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
        // timeout: 1000,
      })
        .then((json) => {
          this.categories = json?.data.data.data;
          this.paginationTotal = json?.data.data.total;
          this.paginationStart = json?.data.data.current_page;
          this.paginationEnd = json?.data.data.per_page;
          this.isLoading = false;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoading = false;
        });
    },
    async removeRow(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}videocategories/delete/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                this.$swal({
                  title: "Deleted",
                  text: "Category deleted successfully",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
                this.getCategory();
                this.$forceUpdate();
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },
  },
};
</script>
<style>
.pagination-button {
  display: flex;
  gap: 10px;
}
.gap-1 {
  gap: 10px;
}
</style>